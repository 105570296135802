<script>
import EzPopupContainer from '@dmant/ez-ui-components/src/components/popup-container';
import { hide } from '@/services/modal';
import i18n from '@/i18n';

export default {
  name: 'EzfConfirmStatsReset',
  components: { EzPopupContainer },
  props: {
    resetCallback: {
      type: Function,
      default: () => () => {},
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async onClick(confirm) {
      if (confirm) {
        this.loading = true;
        try {
          await this.resetCallback();
          this.$notify({
            data: {
              type: 'success',
              content: this.$t('confirmStatsReset.success'),
            },
          });
        } finally {
          this.loading = false;
        }
      }
      hide('confirm-stats-reset-dialog');
    },
  },
  computed: {
    t() {
      return {
        description: i18n.t('confirmStatsReset.description'),
        cancel: i18n.t('confirmStatsReset.buttons.cancel'),
        yes: i18n.t('confirmStatsReset.buttons.yes'),
      };
    },
  },
};
</script>

<template>
  <ez-popup-container
    service-name="funnels"
    @close="onClick(false)"
    icon-header="trash"
  >
    <p>{{ t.description }}</p>

    <template slot="footer">
      <ez-button-group justify="end">
        <ez-button type="secondary" @click="onClick(false)">
          {{ t.cancel }}
        </ez-button>
        <ez-button :preloader="loading" type="danger" @click="onClick(true)">
          {{ t.yes }}
        </ez-button>
      </ez-button-group>
    </template>
  </ez-popup-container>
</template>
